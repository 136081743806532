/* ==========================================================================
    [9. CUSTOM]
========================================================================== */

$big-mockup-width: 920px;

p {
  margin: 1rem 0;
}

.justify {
  text-align: justify;
}

.logo {
  max-width: 180px;
}

.swiper-container {
  .swiper-pagination-top {
    bottom: auto;
    top: 0;
  }
}

hr[class*='bg'] {
  border-top: 0;
  height: 1px;
}

.off-left-background {
  background-position: -60px 20px;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s ease;

  &:hover {
    background-position: -60px 0px;
    transform: translate3d(0, -10px, 0);
  }
}

.nav-process {
  min-height: 60px;
}
