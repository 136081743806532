// [ FONTS ]
$font-family-complementary: $font-family-base !default;
$font-family-awesome: 'Font Awesome 5 Free' !default;

$font-size-tiny: $font-size-base * 0.45 !default;
$font-size-small: $font-size-base * 0.85 !default;
$font-size-regular: $font-size-base !default;
$font-size-1d5: $font-size-base * 1.5 !default;
$font-size-m: $font-size-base * 2 !default;
$font-size-l: $font-size-base * 3.25 !default;
$font-size-xl: $font-size-base * 4.5 !default;
$font-size-2xl: $font-size-base * 6.5 !default;
$font-size-section-heading: 2rem !default;

$headings-letter-spacing: 0.05rem;
