html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Inter', sans-serif !important;
}

@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

$footer-bg: rgb(41, 56, 86);

.footer-dark {
  background-color: $footer-bg;
}

.footer-edge {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(41, 56,86)' fill-opacity='1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 110px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  top: -110px;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 250ms;
}

@import './assets/sass/custom.scss';
